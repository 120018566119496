import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { HelperService } from './service/service';
import { AccessComponent } from './auth/access/access.component';
import { ForgotPasswordComponent } from './auth/forgotpassword/forgotpassword.component';
import { ResetPasswordComponent } from './auth/resetpassword/resetpassword.component';
import { RegisterComponent } from './auth/onboarding/register/register.component';
import {RegisterComponentV2  } from './auth/OnBoardingV2/register/register.component';
import { RegisterStoreComponentV2 } from './auth/onboarding/registerstore/registerstore.component';
import { RegisterStoreComponent } from './auth/OnBoardingV2/registerstore/registerstore.component';
import { RegisterRewardComponent } from './auth/onboarding/registerreward/registerreward.component';
import { RegisterCompleteComponent } from './auth/onboarding/registercomplete/registercomplete.component';
import { ChangePasswordComponent } from './auth/verifybulkmerchants/changepassword/changepassword.component';
import { VerifyNumberComponent } from './auth/verifybulkmerchants/verifynumber/verifynumber.component';
import { VerifyPinComponent } from './auth/verifybulkmerchants/verifypin/verifypin.component';
import { MerchantguardGuard } from './service/guard/merchantguard.guard';
import { NoAccessComponent } from './auth/noaccess/noaccess.component';
import { MerchantRewardComponent } from './auth/merchantrewards/merchantrewards.component';
import { RegisterMerchantComponent } from './auth/merchantrewards/registermerchant/registermerchant.component';
import { TUSubScriptionComponent } from './auth/tusubscription/tusubscription.component';
import { TUTopupComponent } from './modules/accounts/tumerchants/NoAccess/topup.component';
import { TUAllPlansComponent } from './modules/accounts/tumerchants/tuallplans/tuallplans.component';
import { FreePlansComponent } from './modules/accounts/tumerchants/freeplans/freeplans.component';
import { VerifyemailComponent } from './auth/OnBoardingV2/verifyemail/verifyemail.component';
import { checkmailComponent } from './auth/OnBoardingV2/checkmail/verifyemail.component';

import { BusinesslocationComponent } from './auth/OnBoardingV2/businesslocation/businesslocation.component';
import { TUTopupComponent1 } from './modules/accounts/tumerchants/OnBoardFreeTrial/topup.component';
import { AuthComponent } from './auth/windowload/windowload.component';
import { TUUpgradePlanComponent } from './modules/accounts/tumerchants/Upgradeplan/Upgradeplan.component';



const _Routes: Routes = [
  { path: '', redirectTo: 'account/login', pathMatch: 'full' },
  { path: 'login', redirectTo: 'account/login', pathMatch: 'full' },
  { path: 'forgotpassword', redirectTo: 'account/forgotpassword', pathMatch: 'full' },
  { path: 'resetpassword/:referencekey', redirectTo: 'account/resetpassword', pathMatch: 'full' },
  { path: 'resetpassword/:referenceid/:referencekey/:code', redirectTo: 'account/resetpassword/:referenceid/:referencekey/:code', pathMatch: 'full' },
  { path: 'account/webpay/:reference', redirectTo: 'account/webpay/:reference', pathMatch: 'full' },
  { path: 'resetpassword/:referencekey', redirectTo: 'account/resetpassword', pathMatch: 'full' },
  { path: 'access/:referencekey/:username', component: AccessComponent },
  { path: 'login', component: LoginComponent },
  { path: 'account/noaccess', component: NoAccessComponent },
  { path: 'account/login', component: LoginComponent },
  { path: 'account/auth/:AccessKey', component: AuthComponent },
  // { path: 'account/register', component: RegisterComponent },
  { path: 'account/register', component: RegisterComponentV2 },
  { path: 'account/blocation', component: BusinesslocationComponent },

  { path: 'account/verifyemail/:referencecode', component: VerifyemailComponent },
  { path: 'account/checkmail', component: checkmailComponent },

  
  { path: 'account/setup', component: RegisterStoreComponent },
  { path: 'account/merchantregister', component: RegisterMerchantComponent },
  { path: 'account/updatesubscription', component: TUSubScriptionComponent},
  { path: 'account/updatetsubscription', component: TUTopupComponent},
  { path: 'account/TUTopupComponent', component: TUTopupComponent},
  { path: 'account/updatetsubscriptiononboard', component: TUTopupComponent1},
  { path: 'account/upgradeplan', component: TUUpgradePlanComponent},

  { path: 'account/allplans', component: TUAllPlansComponent},
  { path: 'account/setupcomplete', component: RegisterCompleteComponent },
  { path: 'account/freetrail', component: FreePlansComponent },

  { path: 'account/reward', component: RegisterRewardComponent },
  { path: 'account/changepassword', component: ChangePasswordComponent },
  { path: 'account/verifynumber', component: VerifyNumberComponent },
  { path: 'account/verifypin', component:VerifyPinComponent},
  { path: 'account/forgotpassword', component: ForgotPasswordComponent },
  { path: 'account/resetpassword/:referenceid/:referencekey/:code', component: ResetPasswordComponent },
  { path: 'account/resetpassword/:referencekey', component: ResetPasswordComponent },
  { path: 'resetpassword/:referencekey', component: ResetPasswordComponent },
  { path: 'account/webpay/:reference', component: MerchantRewardComponent },
  { canActivate: [MerchantguardGuard], path: '', loadChildren: './panels/merchant/merchant.module#TUMerchantModule' }
  // { canActivate: [HelperService], path: '', loadChildren: './panels/merchant/merchant.module#TUMerchantModule' }

];

@NgModule({
  imports: [RouterModule.forRoot(_Routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
