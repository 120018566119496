import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileManagerComponent } from './file-manager.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { NewFolderDialogComponent } from './modals/new-folder-dialog/new-folder-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { ImageEditorComponent } from './modals/image-editor/image-editor.component';
import { RenameImageComponent } from './modals/rename-image/rename-image.component';
import { SearchFilterPipe } from '../../service/search-filter.pipe';
import {MatChipsModule} from '@angular/material/chips'

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatChipsModule,
  ],
  declarations: [
    FileManagerComponent, 
    NewFolderDialogComponent,
    ImageEditorComponent,
    RenameImageComponent,
    SearchFilterPipe
  ],
  exports: [FileManagerComponent],
  entryComponents: [
    NewFolderDialogComponent, 
    FileManagerComponent,
    ImageEditorComponent,
    RenameImageComponent
  ]
})
export class FileManagerModule {}
