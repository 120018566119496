import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HelperService, OResponse } from '../../../service/service';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
    selector: 'register',
    templateUrl: './register.component.html',
})
export class RegisterComponent implements OnInit {
    Form_AddUser_Processing = false;
    Form_AddUser: FormGroup;
    constructor(
        public _FormBuilder: FormBuilder,
        public _TranslateService: TranslateService,
        public _Router: Router,
        public _HelperService: HelperService,
    ) {

        this.Form_AddUser = _FormBuilder.group({
            OperationType: 'new',
            Task: this._HelperService.AppConfig.Api.Core.onboardmerchant,
            AccountTypeCode: this._HelperService.AppConfig.AccountType.Merchant,
            AccountOperationTypeCode: this._HelperService.AppConfig.AccountOperationType.Offline,
            // RegistrationSourceCode: this._HelperService.AppConfig.RegistrationSource.System,
            // OwnerKey: null,
            // SubOwnerKey: null,
            // BankKey: null,
            Password: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(20),
                    Validators.pattern(
                        this._HelperService.AppConfig.ValidatorRegex.Password
                    )
                ])
            ],
            // DisplayName: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(25)])],
          //  Name: [null, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(256)])],
            // FirstName: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(128)])],
            // LastName: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(128)])],
            EmailAddress: [null, Validators.compose([Validators.required, Validators.email, Validators.minLength(2)])],
           // MobileNumber: [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(14)])],
            //ReferralCode: [null],
           
            // ContactNumber: [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(18)])],
            // SecondaryEmailAddress: [null, Validators.compose([Validators.required, Validators.email, Validators.minLength(2)])],
            // WebsiteUrl: [null, Validators.compose([Validators.minLength(2), Validators.pattern(this._HelperService.AppConfig.ValidatorRegex.WebsiteUrl)])],
            // Description: null,
            // CountValue: 0,
            // AverageValue: 0,
            // ApplicationStatusCode: null,
            // EmailVerificationStatus: 0,
            // EmailVerificationStatusDate: null,
            // NumberVerificationStatus: 0,
            // NumberVerificationStatusDate: null,
            // CountryKey: null,
            // RegionKey: null,
            // RegionAreaKey: null,
            // CityKey: null,
            // CityAreaKey: null,
            // BusinessCategories: null,
            // AccountLevelCode: 'accountlevel.ca',
            // SubscriptionKey: 'merchantbasic',
            // AccountPercentage: 0,
            // StatusCode: this._HelperService.AppConfig.Status.Active,
            // Owners: [],
            // Configuration: [],
        })
    }

    ngOnInit() {
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Account);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Device);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Location);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.OReqH);
        this._HelperService.SetNetwork();
        this.Form_AddUser.reset();

    }
  

    resolved(Item) {
        this.capt = Item;
    }
    ShowPassword: boolean = true;
    ToogleShowHidePassword(): void {
        this.ShowPassword = !this.ShowPassword;
    }

    public capt = undefined;
    // Form_AddUser_Process(_FormValue: any) {
    //     // if (this.capt == undefined) {
    //     //     this._HelperService.NotifyError("Verify captch")
    //     // }
    //     // else{
    //     _FormValue.DisplayName = _FormValue.Name;
    //     this.Form_AddUser_Processing = true;
    //     // _FormValue.Configurations = [
    //     //     {
    //     //         SystemName: 'rewardpercentage',
    //     //         Value: 3,
    //     //     },
    //     //     {
    //     //         SystemName: 'rewardmaxinvoiceamount',
    //     //         Value: '0',
    //     //     },
    //     //     {
    //     //         SystemName: 'rewarddeductiontype',
    //     //         Value: 'Prepay',
    //     //         HelperCode: 'rewarddeductiontype.prepay',
    //     //     },
    //     //     {
    //     //         SystemName: 'maximumrewarddeductionamount',
    //     //         Value: '0',
    //     //     },

    //     //     {
    //     //         SystemName: 'thankucashplus',
    //     //         Value: '0',
    //     //     },
    //     //     {
    //     //         SystemName: 'thankucashplusrewardcriteria',
    //     //         HelperCode: 'rewardcriteriatype.mininvoice',
    //     //         Value: '2000',
    //     //     },
    //     //     {
    //     //         SystemName: 'thankucashplusmintransferamount',
    //     //         Value: '2000',
    //     //     },

    //     //     {
    //     //         SystemName: 'settlementScheduletype',
    //     //         HelperCode: 'settlementSchedule.daily',
    //     //         Value: 'Daily',
    //     //     },
    //     //     {
    //     //         SystemName: 'minimumsettlementamount',
    //     //         Value: '1',
    //     //     },
    //     // ];
    //     this._HelperService.SaveStorage(
    //         this._HelperService.AppConfig.Storage.VerifyNumber, cloneDeep(_FormValue.MobileNumber)

    //     );
    //     // this._HelperService.MerchantEmailAddress = cloneDeep(_FormValue.EmailAddress);
    //     // this._HelperService.MerchantPassword = cloneDeep(_FormValue.Password);

    //     this._HelperService.OnBoardMerchant = _FormValue
    //     // console.log(this._HelperService.OnBoardMerchant )
    //     this._Router.navigate([
    //         this._HelperService.AppConfig.Pages.System.MerchantSetup,

    //     ]);
    //     // _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.Account, _FormValue);

    // }

    Form_AddUser_Process(_FormValue: any) {
      
        _FormValue.DisplayName = _FormValue.Name;
        this.Form_AddUser_Processing = true;
      
        this._HelperService.SaveStorage(
            this._HelperService.AppConfig.Storage.VerifyNumber, cloneDeep(_FormValue.MobileNumber)

        );
        // this._HelperService.MerchantEmailAddress = cloneDeep(_FormValue.EmailAddress);
        // this._HelperService.MerchantPassword = cloneDeep(_FormValue.Password);

        this._HelperService.OnBoardMerchant = _FormValue
        // console.log(this._HelperService.OnBoardMerchant )
        this._Router.navigate([
            this._HelperService.AppConfig.Pages.System.verifymail,

        ]);
        // _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.Account, _FormValue);

    }


}


// }
