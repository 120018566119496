import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HelperService, OResponse } from '../../../service/service';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
    selector: 'register',
    templateUrl: './register.component.html',
})
export class RegisterComponentV2 implements OnInit {
    Form_AddUser_Processing = false;
    Form_AddUser: FormGroup;
    constructor(
        public _FormBuilder: FormBuilder,
        public _TranslateService: TranslateService,
        public _Router: Router,
        public _HelperService: HelperService,
    ) {

        this.Form_AddUser = _FormBuilder.group({
            OperationType: 'new',
            Task: "onboardmerchant_st1",
            AccountTypeCode: this._HelperService.AppConfig.AccountType.Merchant,
            Password: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(20),
                    Validators.pattern(
                        this._HelperService.AppConfig.ValidatorRegex.Password
                    )
                ])
            ],
            EmailAddress: [null, Validators.compose([Validators.required, Validators.email, Validators.minLength(2)])],
            Source: 'Merchant',
            Host: null
        })
    }

    ngOnInit() {
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Account);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Device);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Location);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.OReqH);
        this._HelperService.SetNetwork();
        this.Form_AddUser.reset();
    }


    resolved(Item) {
        this.capt = Item;
    }
    ShowPassword: boolean = true;
    ToogleShowHidePassword(): void {
        this.ShowPassword = !this.ShowPassword;
    }

    public capt = undefined;

    Form_AddUser_Process(_FormValue: any) {
        this.capt = "das";
        if (this.capt == undefined) {
            this._HelperService.NotifyError("Verify captcha")
        }
        else {
            var Request = this.CreateRequestJson();
            this.Form_AddUser_Processing = true;
            let _OResponse: Observable<OResponse>;
            _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.MOnBoard, Request);
            _OResponse.subscribe(
                _Response => {
                    this.Form_AddUser_Processing = false;
                    if (_Response.Status == this._HelperService.StatusSuccess) {
                        this._HelperService.SaveStorage(
                            this._HelperService.AppConfig.Storage.DataReference, cloneDeep(_Response)
                        );
                        this._HelperService.MerchantToken = _Response.Result.Reference;
                        this._Router.navigate([
                            this._HelperService.AppConfig.Pages.System.CheckMail,
                        ]);
                    }
                    else {
                        this._HelperService.NotifyError(_Response.Message);
                    }
                },
                _Error => {
                    this.Form_AddUser_Processing = false;
                    this._HelperService.HandleException(_Error);
                });


        }

    }


    CreateRequestJson(): void {
        var AccountDetail = this._HelperService.OnBoardMerchant
        AccountDetail.Task = this._HelperService.AppConfig.Api.Core.Onboardmerchant_st1,

            AccountDetail.Password = this.Form_AddUser.controls['Password'].value
        AccountDetail.EmailAddress = this.Form_AddUser.controls['EmailAddress'].value
        AccountDetail.Host = this._HelperService.AppConfig.Host

        AccountDetail.Source = 'Merchant'

        this._HelperService.SaveStorage(
            this._HelperService.AppConfig.Storage.VerifyEmail, cloneDeep(AccountDetail.EmailAddress)

        );
        return AccountDetail;
    }


}


// }
