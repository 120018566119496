import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HelperService, OResponse } from '../../service/service';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
    selector: 'merchantrewards',
    templateUrl: './merchantrewards.component.html',
})
export class MerchantRewardComponent implements OnInit {
    _Form_Login_Processing = false;
    _Show_Form_ResetPassword = false;
    _Form_Login: FormGroup;
    _Form_Otp: FormGroup;

    URLData: any = {
        ActiveReferenceKey: null,
        ActiveReferenceId: null,
        Code: null
    };

    MerchantReward: any = {};
    ParsedMerchantReward: any = {
        Reference: null,
        MerchantCode: null,
        Business: {},
        Contact: {}
    };

    MerchantReference: any = {};
    BrowserMerchantReward: any = {}
    constructor(
        public _ActivatedRoute: ActivatedRoute,
        public _FormBuilder: FormBuilder,
        public _TranslateService: TranslateService,
        public _Router: Router,
        public _HelperService: HelperService,
    ) {
        // this.WebPay();

        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Account);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Device);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Location);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.OReqH);
        this._HelperService.SetNetwork();
        this._ActivatedRoute.params.subscribe((params: Params) => {
            this.BrowserMerchantReward = params.reference
            this.MerchantReference = this.BrowserMerchantReward;
            this.BrowserMerchantReward = atob(params.reference);
            // this.MerchantReward = JSON.parse(this.BrowserMerchantReward);
            // this.MerchantReward = this.BrowserMerchantReward;

            this._HelperService.SaveStorage(
                this._HelperService.AppConfig.Storage.MerchantData,
                {
                    Reference: this.BrowserMerchantReward,
                    // MerchantCode: this.MerchantReward.MerchantCode,

                }
            );
            this.ParsedMerchantReward.Reference = this.BrowserMerchantReward;
            // this.ParsedMerchantReward.MerchantCode = this.MerchantReward.MerchantCode;
        });



    }
    ngOnInit() {
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Account);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Device);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Location);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.OReqH);
        this._HelperService.SetNetwork();
        this.WebPay();


    }


    public _WebPay: any =
        {

        }
    WebPay() {

        this._HelperService.IsFormProcessing = true;
        var pData = {
            Task: 'webpayinitialize',
            Reference: this.ParsedMerchantReward.Reference,
            // MerchantCode: null,
        }

        if (this.ParsedMerchantReward.MerchantCode) {
            // pData.MerchantCode = this.ParsedMerchantReward.MerchantCode
            let _OResponse: Observable<OResponse>;
            _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.OnBoardM, pData);
            _OResponse.subscribe(
                _Response => {
                    if (_Response.Status == this._HelperService.StatusSuccess) {
                        this._HelperService.IsFormProcessing = false;
                        this._WebPay = _Response.Result;
                        if (this._WebPay.IsMerchantConnected) {


                            this.NavigateToDashboard();

                        } else {

                            this._HelperService.SaveStorage(
                                this._HelperService.AppConfig.Storage.MerchantRewardPercentage,
                                {
                                    Minimum: this._WebPay.RewardPercentage.Minimum,
                                    Maximum: this._WebPay.RewardPercentage.Maximum,

                                    Name: this._WebPay.Business.Name,
                                    EmailAddress: this._WebPay.Business.EmailAddress,
                                    MobileNumber: this._WebPay.Business.MobileNumber,
                                    Address: this._WebPay.Business.Address,

                                    CName: this._WebPay.Business.Name,
                                    CEmailAddress: this._WebPay.Business.EmailAddress,
                                    CMobileNumber: this._WebPay.Business.MobileNumber

                                }
                            );

                            this._Router.navigate([
                                this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.System.MerchantRegister
                            ])
                        }


                    }
                    else {
                        this._HelperService.IsFormProcessing = false;
                        this._HelperService.NotifyError(_Response.Message);
                        // this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.System.MerchantRegister

                    }
                },
                _Error => {
                    this._HelperService.HandleException(_Error);
                }
            );
        }

        else {
            // pData.MerchantCode = this.ParsedMerchantReward.MerchantCode
            let _OResponse: Observable<OResponse>;
            _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.OnBoardM, pData);
            _OResponse.subscribe(
                _Response => {
                    if (_Response.Status == this._HelperService.StatusSuccess) {
                        this._HelperService.IsFormProcessing = false;
                        this._WebPay = _Response.Result;
                        if (this._WebPay.IsMerchantConnected) {


                            this.NavigateToDashboard();

                        } else {

                            this._HelperService.SaveStorage(
                                this._HelperService.AppConfig.Storage.MerchantRewardPercentage,
                                {
                                    Minimum: this._WebPay.RewardPercentage.Minimum,
                                    Maximum: this._WebPay.RewardPercentage.Maximum,
                                    Name: this._WebPay.Business.Name,
                                    EmailAddress: this._WebPay.Business.EmailAddress,
                                    MobileNumber: this._WebPay.Business.MobileNumber,
                                    Address: this._WebPay.Business.Address,
                                    CName: this._WebPay.Contact.Name,
                                    CEmailAddress: this._WebPay.Contact.EmailAddress,
                                    CMobileNumber: this._WebPay.Contact.MobileNumber
                                }
                            );
                            this._Router.navigate([
                                this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.System.MerchantRegister
                            ])
                        }


                    }
                    else {
                        this._HelperService.IsFormProcessing = false;
                        this._HelperService.NotifyError(_Response.Message);
                        // this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.System.MerchantRegister

                    }
                },
                _Error => {
                    this._HelperService.HandleException(_Error);
                }
            );

        }


    }



    NavigateToDashboard() {

        if (this._WebPay.taccessid != null) {

            this._HelperService.IsFormProcessing = true;
            var pData = {
                Task: 'login',
                UserName: this._WebPay.taccessid,
                Password: this._WebPay.taccesstoken,
                PlatformCode: 'web',
            };
            let _OResponse: Observable<OResponse>;
            _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V1.System, pData);
            _OResponse.subscribe(
                _Response => {
                    this._HelperService.IsFormProcessing = false;
                    if (_Response.Status == this._HelperService.StatusSuccess) {
                        this._HelperService.SaveStorage(this._HelperService.AppConfig.Storage.Account, _Response.Result);
                        var _StorageReqH = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.OReqH);
                        _StorageReqH.hcuak = _Response.Result['AccessKey'];
                        _StorageReqH.hcupk = btoa(_Response.Result['PublicKey']);
                        this._HelperService.SaveStorage(this._HelperService.AppConfig.Storage.OReqH, _StorageReqH);
                        // this._HelperService.NotifySuccess(_Response.Message);

                        if (_Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.Merchant) {
                            window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Merchant;
                        } else if (_Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.SubAccount) {
                            window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Merchant;
                        }

                        else {
                            // this._HelperService.NotifyError('Invalid account. Please contact Support');

                            this._Router.navigate([
                                this._HelperService.AppConfig.Pages.System.Login,

                            ]);

                        }
                    }
                    else {
                        // this._HelperService.NotifyError(_Response.Message);

                        this._Router.navigate([
                            this._HelperService.AppConfig.Pages.System.Login,

                        ]);
                    }
                },
                _Error => {
                    this._HelperService.IsFormProcessing = false;
                    // this._HelperService.HandleException(_Error);

                    this._Router.navigate([
                        this._HelperService.AppConfig.Pages.System.Login,

                    ]);
                });


        } else {
            this._HelperService.NotifyError('Try Again');
        }


    }


}
