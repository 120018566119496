import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/onboarding/register/register.component';
import { RegisterComponentV2 } from './auth/OnBoardingV2/register/register.component';
import { VerifyemailComponent } from './auth/OnBoardingV2/verifyemail/verifyemail.component';
import { RegisterStoreComponentV2 } from './auth/onboarding/registerstore/registerstore.component';


import { RegisterStoreComponent } from './auth/OnBoardingV2/registerstore/registerstore.component';

import { RegisterMerchantComponent } from './auth/merchantrewards/registermerchant/registermerchant.component';

import { ForgotPasswordComponent } from './auth/forgotpassword/forgotpassword.component';
import { ResetPasswordComponent } from './auth/resetpassword/resetpassword.component';
import { MerchantRewardComponent } from './auth/merchantrewards/merchantrewards.component';

import { AccessComponent } from './auth/access/access.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TranslaterModule, HelperService, DataHelperService, FilterHelperService } from './service/service';
import { AgmCoreModule } from '@agm/core';
// import { NgProgressModule } from '@ngx-progressbar/core';
// import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { Ng2FileInputModule } from 'ng2-file-input';
import { AgmOverlays } from "agm-overlays"
import { Ng5SliderModule } from 'ng5-slider';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ArchwizardModule } from 'angular-archwizard';
import { Angular4PaystackModule } from 'angular4-paystack';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { RegisterRewardComponent } from './auth/onboarding/registerreward/registerreward.component';
import { RegisterCompleteComponent } from './auth/onboarding/registercomplete/registercomplete.component';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { from } from 'rxjs';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgOtpInputModule } from 'ng-otp-input'
import { Select2Module } from "ng2-select2";
import { ChangePasswordComponent } from './auth/verifybulkmerchants/changepassword/changepassword.component';
import { VerifyNumberComponent } from './auth/verifybulkmerchants/verifynumber/verifynumber.component';
import { VerifyPinComponent } from './auth/verifybulkmerchants/verifypin/verifypin.component';
import { TUSubScriptionComponent } from './auth/tusubscription/tusubscription.component';
import { TUTopupComponent } from './modules/accounts/tumerchants/NoAccess/topup.component';
import { TUTopupComponent1 } from './modules/accounts/tumerchants/OnBoardFreeTrial/topup.component';

import { TUAllPlansComponent } from './modules/accounts/tumerchants/tuallplans/tuallplans.component';

// import { LoadingBarHttpModule } from '@ngx-loading-bar/http';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { MerchantguardGuard } from './service/guard/merchantguard.guard';
import { NoAccessComponent } from './auth/noaccess/noaccess.component';
import { NgInterswitchModule } from 'ng-interswitch';
import { FreePlansComponent } from './modules/accounts/tumerchants/freeplans/freeplans.component';
import { BusinesslocationComponent } from './auth/OnBoardingV2/businesslocation/businesslocation.component';
import { RegisterRewardComponent2 } from './auth/OnBoardingV2/registerreward/registerreward.component';
import { RegisterCompleteComponent2 } from './auth/OnBoardingV2/registercomplete/registercomplete.component';
import { checkmailComponent } from './auth/OnBoardingV2/checkmail/verifyemail.component';

import { AuthComponent } from './auth/windowload/windowload.component';
import { TUUpgradePlanComponent } from './modules/accounts/tumerchants/Upgradeplan/Upgradeplan.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { FileManagerModule } from 'src/app/modules/file-manager/file-manager.module';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    RegisterStoreComponent,
    RegisterRewardComponent,
    RegisterCompleteComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AccessComponent,
    ChangePasswordComponent,
    VerifyPinComponent,
    VerifyNumberComponent,
    NoAccessComponent,
    MerchantRewardComponent,
    RegisterMerchantComponent,
    TUSubScriptionComponent,
    TUTopupComponent,
    TUAllPlansComponent,
    FreePlansComponent,
    RegisterComponentV2,
    VerifyemailComponent,
    BusinesslocationComponent,
    TUTopupComponent1,
    RegisterStoreComponentV2,
    RegisterCompleteComponent2,
    RegisterRewardComponent2,
    checkmailComponent,
    AuthComponent,
    TUUpgradePlanComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslaterModule,
    Ng5SliderModule,
    ArchwizardModule,
    ImageCropperModule,
    AgmOverlays,
    Angular4PaystackModule,
    NgxJsonViewerModule,
    GooglePlaceModule,
    ChartsModule,
    // NgInterswitchModule,
    // NgProgressModule,
    // NgProgressHttpModule,
    // LoadingBarHttpModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,

    RecaptchaModule,
    NgOtpInputModule,
    Select2Module,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB9DMDX78ll840bMxjGL4WUfjAYXfi8vZo'
    }),
    Ng2FileInputModule.forRoot(),
    BrowserAnimationsModule,
    MatDialogModule,
    FileManagerModule,
  ],
  providers: [
    HelperService,
    DataHelperService,
    FilterHelperService,
    ThemeService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
