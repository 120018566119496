import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { take } from 'rxjs/operators';
import { GalleryService } from '../../../../service/gallery.service';

@Component({
  selector: 'app-rename-image',
  templateUrl: './rename-image.component.html',
  styleUrls: ['./rename-image.component.css']
})
export class RenameImageComponent implements OnInit {
  fileName: string
  
  constructor(
    public dialogRef: MatDialogRef<RenameImageComponent>,
    private gs: GalleryService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {}

  renameFile() {
    this.gs.renameFile(this.data.merchantId, this.data.imgobj.reference, this.fileName).pipe(take(1)).subscribe(res => {
      this.dialogRef.close(true)
    })
  }

}
