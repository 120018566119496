import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DataHelperService, HelperService, OResponse, OSelect } from '../../../service/service';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'app-verifyemail',
  templateUrl: './verifyemail.component.html',
  styleUrls: ['./verifyemail.component.css']
})
export class VerifyemailComponent implements OnInit {
  emailid = null;
  constructor(public _Router: Router,
    public _HelperService: HelperService,
    public _ActivatedRoute: ActivatedRoute,

  ) { }

  URLData: any = {

    Code: null
  };
  Response: any = {
    Reference: null
  }

  ngOnInit() {
    this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Account);
    this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Device);
    this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Location);
    this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.OReqH);
    this._HelperService.SetNetwork();
    this.emailid = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.VerifyEmail)
    this.Response = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.DataReference)

    this._ActivatedRoute.params.subscribe((params: Params) => {

      this.URLData.Code = params.referencecode + "==";

      this.VerifyMail(this.URLData.Code);

    });
  }
  nextpage() {
    this._Router.navigate([
      this._HelperService.AppConfig.Pages.System.MerchantSetup,

    ]);
  }


  public VerifyMail(value): void {
    this._HelperService.IsFormProcessing = true;
    this._HelperService.AppConfig.ShowHeader = true;
    var pData = {
      Task: "onboardmerchant_st3",
      // Reference: this.Response.Result.Reference,
      Source: 'Merchant',
      Token: value,
      Host: this._HelperService.AppConfig.Host


      // Token:this.ngOtpInputRef.getValue(),
    };
    // pData.Reference = this.Response.Result.Reference;
    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.MOnBoard, pData);
    _OResponse.subscribe(
      _Response => {
        if (_Response.Status == this._HelperService.StatusSuccess) {
          this._HelperService.IsFormProcessing = false;

          this._HelperService.SaveStorage(
            this._HelperService.AppConfig.Storage.DataReference, cloneDeep(_Response)
          );

          this._Router.navigate([
            this._HelperService.AppConfig.Pages.System.MerchantSetup,

          ]);

        } else {
          this._HelperService.IsFormProcessing = false;
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.HandleException(_Error);
      }
    );
  }


  public ResendMail(): void {
    this.Response = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.DataReference)
    this._HelperService.IsFormProcessing = true;
    this._HelperService.AppConfig.ShowHeader = true;
    var pData = {
      Task: "onboardmerchant_st2",
      Reference: this.Response.Result.Reference,
      Source: 'Merchant',
      Host: this._HelperService.AppConfig.Host

      // Token:this.ngOtpInputRef.getValue(),
    };
    pData.Reference = this.Response.Result.Reference;

    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.MOnBoard, pData);
    _OResponse.subscribe(
      _Response => {
        if (_Response.Status == this._HelperService.StatusSuccess) {
          this._HelperService.IsFormProcessing = false;

        } else {
          this._HelperService.IsFormProcessing = false;
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.HandleException(_Error);
      }
    );
  }

}
