import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Cookies from '../assets/js/js.cookie.js';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  ngOnInit(): void {

  }
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  constructor(
    private _TranslateService: TranslateService,
    private loadingService: LoadingBarService,
  ) {
    _TranslateService.addLangs(['en']);
    _TranslateService.setDefaultLang('en');
    const browserLang = _TranslateService.getBrowserLang();
    _TranslateService.use(browserLang.match(/en/) ? browserLang : 'en');
  }



}
