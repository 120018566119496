import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DataHelperService, HelperService, OResponse, OSelect } from '../../../service/service';
import { Address } from "ngx-google-places-autocomplete/objects/address";
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
    selector: 'registermerchant',
    templateUrl: './registermerchant.component.html',
})
export class RegisterMerchantComponent implements OnInit {
    Form_AddUser_Processing = false;
    ShowCategorySelector: boolean = true;
    constructor(
        public _FormBuilder: FormBuilder,
        public _TranslateService: TranslateService,
        public _Router: Router,
        public _HelperService: HelperService,
        public _DataHelperService: DataHelperService,
        public _ChangeDetectorRef: ChangeDetectorRef
    ) {

    }
    MerchantData: any = {}
    RewardMerchantPer: any = {}

    ngOnInit() {
        this.Form_AddUser_Load();
        this.GetBusinessCategories();
        var value = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.MerchantData);
        this.MerchantData = value
        var RewardPercentage = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.MerchantRewardPercentage);
        this.RewardMerchantPer = RewardPercentage

    }




    _CurrentAddress: any = {};
    Form_AddUser: FormGroup;
    Contact: any = {};
    public _WebPay: any =
        {

        }
    Form_AddUser_Load() {
        this.Form_AddUser = this._FormBuilder.group({
            Task: this._HelperService.AppConfig.Api.Core.WebPayConfirm,
            Name: [null],
            Address: [null],
            MobileNumber: [null],
            EmailAddress: [null],
            ContactName: [null, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(256)])],
            ContactEmailAddress: [null, Validators.compose([Validators.required, Validators.email, Validators.pattern(this._HelperService.AppConfig.EmailPattern), Validators.minLength(2)])],
            ContactMobileNumber: [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(18)])],
            RewardPercentage: [null, Validators.compose([Validators.required, (control: AbstractControl) => Validators.min(this.RewardMerchantPer.Minimum)(control), (control: AbstractControl) => Validators.max(this.RewardMerchantPer.Maximum)(control)])],

            Password: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(20),
                    Validators.pattern(
                        this._HelperService.AppConfig.ValidatorRegex.Password
                    )
                ])
            ],

            StateName: null,
            CityName: null,
            CountryName: null,
            Latitude: null,
            Longitude: null,
            StatusCode: this._HelperService.AppConfig.Status.Active,
        });
    }
    Form_AddUser_Process(_FormValue: any) {

        var Request = this.CreateRequestJson();
        this.Form_AddUser_Processing = true;
        let _OResponse: Observable<OResponse>;
        _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.OnBoardM, Request);
        _OResponse.subscribe(
            _Response => {
                this.Form_AddUser_Processing = false;
                if (_Response.Status == this._HelperService.StatusSuccess) {
                    this._WebPay = _Response.Result
                    this.NavigateToDashboard();
                    // window.location.href = this._HelperService.AppConfig.Pages.System.MerchantRewardSetup;
                    // this._Router.navigate([
                    //     this._HelperService.AppConfig.Pages.System.MerchantRewardSetup,

                    // ]);
                }
                else {
                    this._HelperService.NotifyError(_Response.Message);
                }
            },
            _Error => {
                this.Form_AddUser_Processing = false;
                this._HelperService.HandleException(_Error);
            });
    }
    Form_AddUser_Address: string = null;
    Form_AddUser_Latitude: number = 0;
    Form_AddUser_Longitude: number = 0;
    Form_AddUser_PlaceMarkerClick(event) {
        this.Form_AddUser_Latitude = event.coords.lat;
        this.Form_AddUser_Longitude = event.coords.lng;
    }
    public Form_AddUser_AddressChange(address: Address) {
        // this.Form_AddUser_Latitude = address.geometry.location.lat();
        // this.Form_AddUser_Longitude = address.geometry.location.lng();
        // this.Form_AddUser_Address = address.formatted_address;

        this.Form_AddUser_Latitude = address.geometry.location.lat();
        this.Form_AddUser_Longitude = address.geometry.location.lng();
        this.Form_AddUser_Address = address.formatted_address;
        this.Form_AddUser.controls['Address'].patchValue(address.formatted_address);
        this._CurrentAddress = this._HelperService.GoogleAddressArrayToJson(address.address_components);
        this.Form_AddUser.controls['CityName'].patchValue(this._CurrentAddress.sublocality_level_2);
        this.Form_AddUser.controls['StateName'].patchValue(this._CurrentAddress.sublocality_level_1);
        this.Form_AddUser.controls['CountryName'].patchValue(this._CurrentAddress.country);
        this.Form_AddUser.controls['Latitude'].patchValue(this.Form_AddUser_Latitude);
        this.Form_AddUser.controls['Longitude'].patchValue(this.Form_AddUser_Longitude);
    }

    CreateRequestJson(): void {
        var AccountDetail = this._HelperService.OnBoardMerchant
        AccountDetail.Task = this._HelperService.AppConfig.Api.Core.WebPayConfirm,
            AccountDetail.Reference = this.MerchantData.Reference,



            AccountDetail.Business = {
                Name: this.RewardMerchantPer.Name,
                StatusCode: this.Form_AddUser.controls['StatusCode'].value,
                EmailAddress: this.RewardMerchantPer.EmailAddress,
                MobileNumber: this.RewardMerchantPer.MobileNumber,
                Address: this.RewardMerchantPer.Address,
                Password: this.Form_AddUser.controls['Password'].value,
                RewardPercentage: this.Form_AddUser.controls['RewardPercentage'].value,
            }


        AccountDetail.Contact = {
            Name: this.Form_AddUser.controls['ContactName'].value,
            EmailAddress: this.Form_AddUser.controls['ContactEmailAddress'].value,
            MobileNumber: this.Form_AddUser.controls['ContactMobileNumber'].value,

        }


        if (this.SelectedBusinessCategories != undefined && this.SelectedBusinessCategories.length > 0) {
            AccountDetail.Categories = []
            this.SelectedBusinessCategories.forEach(element => {
                AccountDetail.Categories.push(
                    {
                        ReferenceKey: element.key,
                        ReferenceId: element.id
                    }
                )
            });
        }

        return AccountDetail;
    }

    public BusinessCategories = [];
    public S2BusinessCategories = [];

    GetBusinessCategories() {

        this._HelperService.ToggleField = true;
        var PData =
        {
            Task: this._HelperService.AppConfig.Api.Core.getcategories,
            SearchCondition: this._HelperService.GetSearchConditionStrict('', 'StatusCode', this._HelperService.AppConfig.DataType.Text, 'default.active', '='),
            SortExpression: 'Name asc',
            Offset: 0,
            Limit: 1000,
        }
        PData.SearchCondition = this._HelperService.GetSearchConditionStrict(
            PData.SearchCondition,
            "TypeCode",
            this._HelperService.AppConfig.DataType.Text,
            this._HelperService.AppConfig.HelperTypes.MerchantCategories,
            "="
        );
        let _OResponse: Observable<OResponse>;
        _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.Op, PData);
        _OResponse.subscribe(
            _Response => {
                if (_Response.Status == this._HelperService.StatusSuccess) {
                    if (_Response.Result.Data != undefined) {
                        this.BusinessCategories = _Response.Result.Data;

                        this.ShowCategorySelector = false;
                        this._ChangeDetectorRef.detectChanges();
                        for (let index = 0; index < this.BusinessCategories.length; index++) {
                            const element = this.BusinessCategories[index];
                            this.S2BusinessCategories.push(
                                {
                                    id: element.ReferenceId,
                                    key: element.ReferenceKey,
                                    text: element.Name
                                }
                            );
                        }
                        this.ShowCategorySelector = true;
                        this._ChangeDetectorRef.detectChanges();

                        this._HelperService.ToggleField = false;

                    }
                }
                else {
                    this._HelperService.NotifyError(_Response.Message);
                }
            },
            _Error => {
                this._HelperService.IsFormProcessing = false;
                this._HelperService.HandleException(_Error);
                this._HelperService.ToggleField = false;

            });
    }
    public SelectedBusinessCategories = [];
    CategoriesSelected(Items) {

        if (Items != undefined && Items.value != undefined && Items.value.length > 0) {
            this.SelectedBusinessCategories = Items.data;
        }
        else {
            this.SelectedBusinessCategories = [];
        }


    }
    SaveMerchantBusinessCategory(item) {
        if (item != '0') {
            var Setup =
            {
                Task: this._HelperService.AppConfig.Api.Core.SaveUserParameter,
                TypeCode: this._HelperService.AppConfig.HelperTypes.MerchantCategories,
                // UserAccountKey: this._UserAccount.ReferenceKey,
                CommonKey: item,
                StatusCode: 'default.active'
            };
            let _OResponse: Observable<OResponse>;
            _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V2.System, Setup);
            _OResponse.subscribe(
                _Response => {
                    if (_Response.Status == this._HelperService.StatusSuccess) {
                        this._HelperService.NotifySuccess('Business category assigned to merchant');
                        // this.BusinessCategories = [];
                        // this.GetMerchantBusinessCategories();
                    }
                    else {
                        this._HelperService.NotifyError(_Response.Message);
                    }
                },
                _Error => {
                    this._HelperService.IsFormProcessing = false;
                    this._HelperService.HandleException(_Error);
                });
        }
    }


    NavigateToDashboard() {

        if (this._WebPay.taccessid != null) {

            this._HelperService.IsFormProcessing = true;
            var pData = {
                Task: 'login',
                UserName: this._WebPay.taccessid,
                Password: this._WebPay.taccesstoken,
                PlatformCode: 'web',
            };
            let _OResponse: Observable<OResponse>;
            _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V1.System, pData);
            _OResponse.subscribe(
                _Response => {
                    this._HelperService.IsFormProcessing = false;
                    if (_Response.Status == this._HelperService.StatusSuccess) {
                        this._HelperService.SaveStorage(this._HelperService.AppConfig.Storage.Account, _Response.Result);
                        var _StorageReqH = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.OReqH);
                        _StorageReqH.hcuak = _Response.Result['AccessKey'];
                        _StorageReqH.hcupk = btoa(_Response.Result['PublicKey']);
                        this._HelperService.SaveStorage(this._HelperService.AppConfig.Storage.OReqH, _StorageReqH);
                        // this._HelperService.NotifySuccess(_Response.Message);

                        if (_Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.Merchant) {
                            window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Merchant;
                        } else if (_Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.SubAccount) {
                            window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Merchant;
                        }

                        else {
                            // this._HelperService.NotifyError('Invalid account. Please contact Support');

                            this._Router.navigate([
                                this._HelperService.AppConfig.Pages.System.Login,

                            ]);

                        }
                    }
                    else {
                        // this._HelperService.NotifyError(_Response.Message);

                        this._Router.navigate([
                            this._HelperService.AppConfig.Pages.System.Login,

                        ]);
                    }
                },
                _Error => {
                    this._HelperService.IsFormProcessing = false;
                    // this._HelperService.HandleException(_Error);

                    this._Router.navigate([
                        this._HelperService.AppConfig.Pages.System.Login,

                    ]);
                });


        } else {
            this._HelperService.NotifyError('Try Again');
        }


    }



}
