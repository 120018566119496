import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HelperService, OResponse } from '../../service/service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  ShowPassword: boolean = true;
  _Form_Login_Processing = false;
  _Form_Login: FormGroup;
  constructor(
    public _FormBuilder: FormBuilder,
    public _TranslateService: TranslateService,
    public _Router: Router,
    public _HelperService: HelperService,
  ) {
    this._Form_Login = _FormBuilder.group({
      'username': ['', Validators.required],
      'password': ['', Validators.required],
    });

  }
  ngOnInit() {
    this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Account);
    this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Device);
    this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Location);
    this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.OReqH);
    this._HelperService.SetNetwork();

    window.location.href = this._HelperService.AppConfig.AccountPanelURL + '/account/login';

  }

  navigateToRegister() {
    window.location.href = this._HelperService.AppConfig.AccountPanelURL + 'account/register';


  }

  // _Form_Login_Process(value: any) {
  //     this._Form_Login_Processing = true;
  //     var pData = {
  //         Task: 'login',
  //         UserName: value.username,
  //         Password: value.password,
  //         PlatformCode: 'web',
  //     };
  //     this._HelperService.SaveStorage(
  //         this._HelperService.AppConfig.Storage.Credentials,
  //         {
  //             UserName: pData.UserName,
  //             Password: pData.Password,
  //             PlatformCode: 'web',
  //         }
  //     );
  //     let _OResponse: Observable<OResponse>;
  //     _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V1.System, pData);
  //     _OResponse.subscribe(
  //         _Response => {
  //             this._Form_Login_Processing = false;
  //             if (_Response.Status == this._HelperService.StatusSuccess) {
  //                 this._HelperService.SaveStorage(this._HelperService.AppConfig.Storage.Account, _Response.Result);
  //                 var _StorageReqH = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.OReqH);
  //                 _StorageReqH.hcuak = _Response.Result['AccessKey'];
  //                 _StorageReqH.hcupk = btoa(_Response.Result['PublicKey']);
  //                 this._HelperService.SaveStorage(this._HelperService.AppConfig.Storage.OReqH, _StorageReqH);
  //                 this._HelperService.NotifySuccess(_Response.Message);
  //                 this._Form_Login.reset();
  //                 // if (_Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.PosAccount) {
  //                 //     window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.PTSP;
  //                 // }
  //                 if (_Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.Merchant ||
  //                     _Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.SubAccount) {

  //                     let UserAccountTypes = _Response.Result.UserAccountTypes
  //                     this._HelperService.SaveStorage("hcuat", UserAccountTypes)
  //                     if (UserAccountTypes && UserAccountTypes.length > 0) {
  //                         if (UserAccountTypes.length == 2) {
  //                             this._HelperService.SaveStorage("toggleDB", true);
  //                             this._HelperService.SaveStorage("hcaDisplayLoyalty", true)
  //                             window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.LoyaltyDashboard
  //                         } else {
  //                             this._HelperService.SaveStorage("toggleDB", false);
  //                             if (UserAccountTypes[0].TypeCode.includes("thankucashdeals")) {
  //                                 this._HelperService.SaveStorage("hcaDisplayLoyalty", false)
  //                                 window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.DealsDashboard
  //                             }
  //                             else {
  //                                 this._HelperService.SaveStorage("hcaDisplayLoyalty", true)
  //                                 if(_Response.Result.User.Address){
  //                                     localStorage.setItem('addbusinesslocation', 'close')
  //                                     window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.LoyaltyDashboard
  //                                 }else{
  //                                     localStorage.setItem('addbusinesslocation', 'open');
  //                                     window.location.href = `${this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.LoyaltyDashboard}?addbusinesslocation=true`;
  //                                 }
  //                             }
  //                         }
  //                     }else {
  //                         this._HelperService.SaveStorage("toggleDB", true);
  //                         this._HelperService.SaveStorage("hcaDisplayLoyalty", true)
  //                         window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.LoyaltyDashboard
  //                     }
  //                     // var Storage = this._HelperService.GetStorage("templocmerchant");
  //                     // if (Storage != null) {

  //                     //     if (Storage.StorageRoute != undefined) {

  //                     //         if (this._HelperService.templocmerchant == true) {
  //                     //             window.location.href = Storage.StorageRoute;
  //                     //         }
  //                     //         else {
  //                     //             window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Merchant;
  //                     //         }
  //                     //     }
  //                     //     else {
  //                     //         window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Merchant;
  //                     //     }

  //                     // }
  //                     // else {
  //                     //     window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Merchant;
  //                     // }

  //                 }
  //                 // else if (_Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.MerchantSubAccount) {
  //                 //     window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.LoyaltyDashboard;
  //                 // }
  //                 // else if (_Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.Merchant) {
  //                 //     window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.LoyaltyDashboard;
  //                 // }
  //                 // else if (_Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.RelationshipManager) {
  //                 //     window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Rm;
  //                 // }
  //                 // else if (_Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.Store) {
  //                 //     window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Store;
  //                 // }
  //                 // else if ((this._HelperService.AppConfig.Host == "console.thankucash.com" || this._HelperService.AppConfig.Host == "testconsole.thankucash.com" || this._HelperService.AppConfig.Host == "betaconsole.thankucash.com") && _Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.Admin) {
  //                 //     window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Console;
  //                 // }
  //                 // else if ((this._HelperService.AppConfig.Host == "console.thankucash.com" || this._HelperService.AppConfig.Host == "testconsole.thankucash.com" || this._HelperService.AppConfig.Host == "betaconsole.thankucash.com") && _Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.Controller) {
  //                 //     window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Console;
  //                 // }
  //                 // else if (this._HelperService.AppConfig.Host == "localhost:4200" && _Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.Controller) {
  //                 //     window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Console;
  //                 // }
  //                 // else if (this._HelperService.AppConfig.Host == "localhost:4200" && _Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.Admin) {
  //                 //     window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Console;
  //                 // }
  //                 // else if (this._HelperService.AppConfig.Host == "localhost:4201" && _Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.Controller) {
  //                 //     window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Console;
  //                 // }
  //                 // else if (this._HelperService.AppConfig.Host == "localhost:4201" && _Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.Admin) {
  //                 //     window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Console;
  //                 // }
  //                 else {
  //                     this._HelperService.NotifyError('Invalid account. Please contact Support');
  //                 }
  //             }
  //             else {
  //                 this._HelperService.NotifyError(_Response.Message);
  //             }
  //         },
  //         _Error => {
  //             this._Form_Login_Processing = false;
  //             this._HelperService.HandleException(_Error);
  //         });
  // }
  ToogleShowHidePassword(): void {
    this.ShowPassword = !this.ShowPassword;
  }
}

enum HostType {
  Live,
  Test,
  Tech,
  Dev
}