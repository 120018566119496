import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { take } from 'rxjs/operators';
import { GalleryService } from '../../../../service/gallery.service';
import ImageEditor from 'tui-image-editor';
import * as $ from 'jquery';

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.css']
})
export class ImageEditorComponent implements OnInit, AfterViewChecked {

  imgsrc
  private _tuiImageEditor!: ImageEditor;
  isImageLoaded = false

  public myTheme: any = {
    'common.bi.image': '',
    'common.bisize.width': '0px',
    'common.bisize.height': '0px',
    'common.backgroundImage': 'none',
    'common.backgroundColor': '#fff',
    'common.border': '1px solid #c1c1c1',
    'downloadButton.display': 'none',

    // header
    'header.backgroundImage': 'none',
    'header.backgroundColor': 'transparent',
    'header.border': '0px',

    // load button
    'loadButton.backgroundColor': '#0168fa',
    'loadButton.border': '1px solid #ddd',
    'loadButton.color': 'white',
    'loadButton.fontFamily': "'IBM Plex Sans', sans-serif",
    'loadButton.fontSize': '12px',
    'loadButton.borderRadius': '6px',

    // download button
    'downloadButton.backgroundColor': '#fdba3b',
    'downloadButton.border': '1px solid #fdba3b',
    'downloadButton.color': '#fff',
    'downloadButton.fontFamily': "'Noto Sans', sans-serif",
    'downloadButton.fontSize': '12px',

    // main icons
    'menu.normalIcon.color': '#8a8a8a',
    'menu.activeIcon.color': '#555555',
    'menu.disabledIcon.color': '#434343',
    'menu.hoverIcon.color': '#e9e9e9',
    'menu.iconSize.width': '24px',
    'menu.iconSize.height': '24px',

    // submenu icons
    'submenu.normalIcon.color': '#8a8a8a',
    'submenu.activeIcon.color': '#555555',
    'submenu.iconSize.width': '32px',
    'submenu.iconSize.height': '32px',

    // submenu primary color
    'submenu.backgroundColor': 'transparent',
    'submenu.partition.color': '#e5e5e5',

    // submenu labels
    'submenu.normalLabel.color': '#858585',
    'submenu.normalLabel.fontWeight': 'normal',
    'submenu.activeLabel.color': '#000',
    'submenu.activeLabel.fontWeight': 'normal',

    // checkbox style
    'checkbox.border': '1px solid #ccc',
    'checkbox.backgroundColor': '#fff',

    // rango style
    'range.pointer.color': '#333',
    'range.bar.color': '#ccc',
    'range.subbar.color': '#606060',

    'range.disabledPointer.color': '#d3d3d3',
    'range.disabledBar.color': 'rgba(85,85,85,0.06)',
    'range.disabledSubbar.color': 'rgba(51,51,51,0.2)',

    'range.value.color': '#000',
    'range.value.fontWeight': 'normal',
    'range.value.fontSize': '11px',
    'range.value.border': '0',
    'range.value.backgroundColor': '#f5f5f5',
    'range.title.color': '#000',
    'range.title.fontWeight': 'lighter',

    // colorpicker style
    'colorpicker.button.border': '0px',
    'colorpicker.title.color': '#000',
  };
  @ViewChild('tuiRef')
  private _tuiRef!: ElementRef<HTMLDivElement>;

  constructor(
    private gs: GalleryService,
    public dialogRef: MatDialogRef<ImageEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdRef: ChangeDetectorRef) { }

  ngOnInit() { }

  public ngAfterViewInit() {
    this._createImageEditor();
    const editorElem = (<HTMLElement>this._tuiRef.nativeElement).querySelector(
      '.tui-image-editor'
    );
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  fileDropped(e) {
    this.isImageLoaded = true
    this._tuiImageEditor.loadImageFromFile(e.target.files[0]).then(() => {
      this._tuiImageEditor.clearUndoStack()
    })
  }

  private _createImageEditor() {
    this.isImageLoaded = this.data.imageURL ? true : false
    this._tuiImageEditor = new ImageEditor(this._tuiRef.nativeElement, {
      includeUI: {
        loadImage: {
          path: this.data.imageURL ? this.data.imageURL : 'assets/img/drop.png',
          // path: this.data.imageURL ? this.data.imageURL.replace("https://s3.eu-west-2.amazonaws.com/","") :'assets/img/chooseimg.png',
          name: 'SampleImage',
        },
        menu: ['resize', 'crop', 'flip', 'rotate', 'filter', 'mask'],
        theme: this.myTheme,
        // locale: locale_ru_RU,
        // theme: whiteTheme, // or whiteTheme
        // initMenu: 'filter',
        menuBarPosition: 'right',
      },
      cssMaxWidth: 700,
      cssMaxHeight: 1000,
      usageStatistics: false,
      selectionStyle: {
        cornerSize: 20,
        rotatingPointOffset: 70,
      },
    });

    $(".tui-image-editor-icpartition").parent(".tui-image-editor-item").hide()

    $(".tui-image-editor-load-btn").on('change', (e) => {
      this.isImageLoaded = true
    })

    $('.tui-image-editor-header-buttons .tui-image-editor-download-btn').
      replaceWith('<button class="tui-image-editor-clear-btn">Clear</button>')

    $(".tui-image-editor-clear-btn").on('click', (e) => {
      if (this.isImageLoaded) {
        this._createImageEditor()
      }
    })

  }

  dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  save() {
    var img = new Image();
    img.src = this._tuiImageEditor.toDataURL();

    img.onload = (event) => {
      let loadedImage: any = event.currentTarget;
      let width = loadedImage.width;
      let height = loadedImage.height;

      var blob = this.dataURLtoBlob(this._tuiImageEditor.toDataURL());
      const file = new File([blob], '' + Date.now(), {
        type: "image/jpeg",
        lastModified: 1,
      });

      const _FormData = new FormData();
      _FormData.append('merchantId', this.data.merchantId);
      _FormData.append('folderReference', this.data.folderref ? this.data.folderref : '');
      _FormData.append('name', file.name);
      _FormData.append('extension', blob.type.split('/')[1]);
      _FormData.append('file', file);
      _FormData.append('size', JSON.stringify(file.size));
      _FormData.append('width', JSON.stringify(width));
      _FormData.append('height', JSON.stringify(height));
      _FormData.append('tags', JSON.stringify([
        {
          key: "size",
          value: blob.size
        },
        {
          key: "fomat",
          value: blob.type.split('/')[1]
        }
      ]));
      this.gs.uploadFile(_FormData).pipe(take(1)).subscribe(res => {
        this.dialogRef.close(true)
      })
    }
  }
}