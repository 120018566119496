import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { HelperService } from "./service";

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(
    private http: HttpClient,
    private _HelperService: HelperService
  ) { }

  getFiles(merchantId, folderReference, offset, limit) {
    return this.http
      .post<any>(`${this._HelperService.AppConfig.MediaManagerConnect}/getfiles`, {
        merchantId: merchantId,
        folderReference: folderReference,
        offset: offset,
        limit: limit
      })
  }

  getfolders(merchantId, offset, limit) {
    return this.http
      .post<any>(`${this._HelperService.AppConfig.MediaManagerConnect}/getfolders`, {
        merchantId: merchantId,
        offset: offset,
        limit: limit
      })
  }

  addFolder(merchantId, folderName, path) {
    return this.http
      .post<any>(`${this._HelperService.AppConfig.MediaManagerConnect}/createfolder`, {
        merchantId: merchantId,
        folderName: folderName,
        path: path
      })
  }

  uploadFile(fileobj) {
    return this.http
      .post<any>(`${this._HelperService.AppConfig.MediaManagerConnect}/uploadfile`, fileobj)
      .pipe(map((data) => { }))
  }

  deleteFile(merchantId, reference) {
    return this.http
      .post<any>(`${this._HelperService.AppConfig.MediaManagerConnect}/deletefile`, {
        merchantId: merchantId,
        reference: reference
      })
  }

  deleteFolder(merchantId, reference) {
    return this.http
      .post<any>(`${this._HelperService.AppConfig.MediaManagerConnect}/deletefolder`, {
        merchantId: merchantId,
        reference: reference
      })
  }

  renameFile(merchantId, reference, name) {
    return this.http
      .post<any>(`${this._HelperService.AppConfig.MediaManagerConnect}/renamefile`, {
        merchantId: merchantId,
        reference: reference,
        name: name
      })
  }
}


