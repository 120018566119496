import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { GalleryService } from 'src/app/service/gallery.service';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-new-folder-dialog',
  templateUrl: './new-folder-dialog.component.html',
  styleUrls: ['./new-folder-dialog.component.css']
})
export class NewFolderDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<NewFolderDialogComponent>,
    private gs: GalleryService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  folderName: string;
  path: string

  ngOnInit() { }

  createFolder() {
    this.path = this.data.path ? this.data.path + this.folderName : this.folderName
    this.gs.addFolder(this.data.merchantId, this.folderName, this.path).pipe(take(1)).subscribe(res => {
      this.dialogRef.close(true)
    })
  }
}


